import { useRouter } from 'vue-router'
import { getToken } from '@/utils/auth'
import { useUserStore } from "@/store/modules/user"
import { useSettingStore } from '@/store/modules/setting'
import { encrypt } from '@/utils/aes'
export default function usePermission() {
  const router = useRouter()
  function isLogin() {
    return new Promise((resolve, reject) => {
      if (!!getToken()) {
        resolve()
      } else {
        $dialog.warning({
          title: '系统提示',
          content: '尚未登陆，您可以继续留在该页面，或者前往登录',
          negativeText: '取消',
          positiveText: '前往登录',
          maskClosable: false,
          closeOnEsc: false,
          onPositiveClick() {
            // 点击确认
            if(import.meta.env.MODE==="development"){
              router.push('/login')
              return
            }
            location.href = import.meta.env.VITE_LOGOUT_PATH + encrypt(window.location.href)
          },
          onClose() {

          }
        });
        reject()
      }
    })
  }
  function isInStatus() {
    const { projectStatus } = useUserStore()
    return new Promise((resolve, reject) => {
      if (!projectStatus) {
        $dialog.warning({
          title: '系统提示',
          content: '该账号无法使用AI实训功能，请联系管理员',
          positiveText: '知道了',
          maskClosable: false,
          closeOnEsc: false,
          onPositiveClick() {
            // 点击确认
          },
          onClose() {

          }
        });
        reject()
      } else {
        resolve()
      }
    })
  }
  function isOpenAigc() {
    const { isAigc } = useSettingStore()
    return new Promise((resolve, reject) => {
      if (!isAigc) {
        $dialog.warning({
          title: '系统提示',
          content: '尚未开通AIGC功能，请联系管理员',
          positiveText: '知道了',
          maskClosable: false,
          closeOnEsc: false,
          onPositiveClick() {
            // 点击确认
          },
          onClose() {

          }
        });
        reject()
      } else {
        resolve()
      }
    })
  }
  function chatRoteAndIslogin() {
    return new Promise((resolve, reject) => {
      isLogin().then(() => {
        return isInStatus()
      }).then(() => {
        return isOpenAigc()
      }).then(()=>{
        resolve()
      }).catch(() => {
        reject()
      })
    })

  }
  return {
    isLogin,
    isInStatus,
    isOpenAigc,
    chatRoteAndIslogin
  };
}
