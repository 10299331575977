import { getToken, setToken, removeToken } from '@/utils/auth.js'
import { login, getInfo } from '@/api/user';
import { getUserInProjectStatus } from "@/api/common"
import {useSettingStore} from "./setting"
export const useUserStore = defineStore(
  'user',
  {
    state:()=>({
      token: getToken(),
      userInfo:{},
      userId:undefined,
      avatar:undefined,
      projectStatus:false
    }),
    actions:{
      // user login
      getLogin(accountInfo) {
        return new Promise(async (resolve, reject) => {
          const {data,error} = await login(accountInfo)
          if(!error){
            this.token = data.data.token
            setToken(data.data.token)
            resolve(data)
          }else{
            reject(error)
          }
        })
      },
      getUserInfo() {
        return new Promise(async (resolve, reject) => {
          const {data:proStatusData, error:proStatusErr} = await getUserInProjectStatus()
          useSettingStore().getProjectInfo()
          if(!proStatusErr){
            this.projectStatus = !proStatusData.data.status
            if(!this.projectStatus){
              return resolve({data:null, error:'暂无权限'})
            }
          }
          const {data,error} = await getInfo()
          if(!error){
            this.userInfo = data.data
            this.userId = this.userInfo.id
            this.avatar = this.userInfo.avatar
            resolve({data:data.data,error:null})
          }else{
            resolve({data:null, error:error})
          }
        })
      },
      resetStore(){
        removeToken()
        this.token = undefined
        this.userInfo = {}
        removeToken()
      }
    }
  }
)
