

<script setup>
import { defineComponent, h } from 'vue'
import { useDialog, useLoadingBar, useMessage, useNotification } from 'naive-ui'

function regeisterNaiveToos(){
  window.$dialog = useDialog()
  window.$loadingBar = useLoadingBar()
  window.$message = useMessage()
  window.$notification = useNotification()
}
const NaiveProviderContent = defineComponent({
  setup(){
    regeisterNaiveToos()
  },
  render() {
    return h('div')
  },
})
</script>

<style lang="scss" scoped>

</style>
<template>
  <NLoadingBarProvider>
    <NDialogProvider>
      <NNotificationProvider>
        <NMessageProvider>
          <slot />
          <NaiveProviderContent />
        </NMessageProvider>
      </NNotificationProvider>
    </NDialogProvider>
  </NLoadingBarProvider>
</template>
