<template>
  <NConfigProvider
    :theme="theme"
    :theme-overrides="themeOverrides"
    style="height: 100%;">
    <NaiveProvider>
      <RouterView />
    </NaiveProvider>
  </NConfigProvider>
</template>
<script setup>
import { useTheme } from '@/hooks/useTheme'
const { theme, themeOverrides } = useTheme()
</script>
