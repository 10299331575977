export const useAppStore = defineStore('app-store',{
  state:()=>({
    siderCollapsed: false,
    theme: 'light',
    navHeight:40,
    footerHeight:20
  }),
  actions: {
    setSiderCollapsed(collapsed) {
      this.siderCollapsed = collapsed
    },

    setTheme(theme) {
      this.theme = theme
    },
  }
})
