<template>
  <NLayoutSider
    :width="260"
    collapse-mode="transform"
    position="absolute"
    :style="getMobileClass"
    @update-collapsed="handleUpdateCollapsed"
  >
  <!--
  :collapsed="collapsed"
    :collapsed-width="0"
  :show-trigger="isMobile ? false : 'arrow-circle'" -->
    <div class="flex flex-col h-full">
      <Head/>
      <List class=" flex-1 overflow-y-auto"/>
      <Footer/>
    </div>
  </NLayoutSider>
  <template v-if="isMobile">
    <div v-show="!collapsed" class="mobile_model" @click="handleUpdateCollapsed" />
  </template>
</template>

<script setup>
import zuoQuan from "@/assets/img/zuo_quan.png"
import zuoCaihong from "@/assets/img/zuo_caihong.png"

import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore } from '@/store/modules/app';
import List from './list.vue';
import Head from "./head.vue";
import Footer from "./footer.vue";
const appStore = useAppStore()
const collapsed = computed(() => appStore.siderCollapsed);
const { isMobile } = useBasicLayout()
const getMobileClass = computed(() => {
  // if (isMobile.value) {
  //   return {
  //     position: 'fixed',
  //     zIndex: 50,
  //     '--n-color':'#0000'
  //   }
  // }
  return {
    '--n-color':'#0000',
    zIndex:-1,
    backgroundImage:`url('${zuoCaihong}'), url('${zuoQuan}')`,
    backgroundSize:'auto,cover',
    backgroundPosition: 'left bottom, top right',
    backgroundRepeat:'no-repeat,no-repeat'
  }
})
function handleUpdateCollapsed() {
  appStore.setSiderCollapsed(!collapsed.value)
}
const mobileSafeArea = computed(() => {
  if (isMobile.value) {
    return {
      paddingBottom: 'env(safe-area-inset-bottom)',
    }
  }
  return {}
})
watch(
  isMobile,
  (val) => {
    appStore.setSiderCollapsed(val)
  },
  {
    immediate: true,
    flush: 'post',
  },
)
</script>

<style lang="scss" scoped>
.mobile_model{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 40;
  background-color: rgba(0,0,0,0.5);
}
</style>
