import { createWebHistory, createWebHashHistory,createMemoryHistory, createRouter } from 'vue-router'
import Layout from '@/layout'
import {createProgressGuard} from "./progress"
const { VITE_ROUTER_HISTORY_MODE = 'history', VITE_BASE_URL } = import.meta.env;
const historyCreatorMap={
  hash: createWebHashHistory,
  history: createWebHistory,
  memory: createMemoryHistory
}
export const createBuiltinVueRoutes = [
  {
    path: '',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name:'Home',
        component: () => import('@/views/home/index')
      },{
        path: '/ai-message',
        name:'AiMessage',
        component: () => import('@/views/message/msgLayout/index'),
        meta:{
          name:'AI对话'
        },
        children:[
          {
            path: 'create-chart',
            name:'MessageCreateChart',
            component: () => import('@/views/message/createChart/index'),
            meta:{
              name:'创建对话',
              redirect:'AiMessage'
            }
          },{
            path: 'chart/:chartId/:modelId',
            name:'MessageChart',
            component: () => import('@/views/message/chart/index'),
            meta:{
              name:'对话详情',
              redirect:'AiMessage'
            }
          }
        ]
      },{
        path: '/practical-training',
        name:'PracticalTraining',
        component: () => import('@/views/practicalTraining/index'),
        meta:{
          name:'AI实训'
        },
        children:[{
            path: 'chart/:chartId/:modelId',
            name:'TrainingChart',
            component: () => import('@/views/practicalTraining/chart/index'),
            meta:{
              name:'对话详情',
              redirect:'PracticalTraining'
            }
          }
        ]
      },{
        path: '/ai-tool',
        name:'AiTool',
        component: () => import('@/views/aiTool/index'),
        meta:{
          name:'AI工具'
        },
      },{
        path: '/ai-course',
        name:'AiCourse',
        component: () => import('@/views/aiCourse/index'),
        meta:{
          name:'AI课程'
        },
      },
      {
        path: '/ai-video',
        name:'AiVideo',
        component: () => import('@/views/aiVideo/index'),
        meta:{
          name:'AI视频'
        },
      },
      {
        path: '/ai-music',
        name:'AiMusic',
        component: () => import('@/views/aiMusic/index'),
        meta:{
          name:'AI音乐'
        },
      },
      {
        path: '/ai-draw',
        name:'AiDraw',
        component: () => import('@/views/aiDraw/index'),
        meta:{
          name:'AI绘画'
        },
      },
      {
        path: '/ai-batch-generation',
        name:'AiBatchGeneration',
        component: () => import('@/views/aiBatchGeneration/index'),
        meta:{
          name:'AI批量生成'
        },
      },
      {
        path: '/ai-sub-task',
        name:'AiSubTask',
        component: () => import('@/views/aiBatchGeneration/components/subTask'),
        meta:{
          name:'AI批量生成子任务'
        },
      },
      {
        path: '/ai-long-sub-task',
        name:'AiLongSubTask',
        component: () => import('@/views/aiLongFormWriting/components/subTask'),
        meta:{
          name:'AI长篇写作子任务'
        },
      },
      {
        path: '/ai-long-form-writing',
        name:'AiLongFormWriting',
        component: () => import('@/views/aiLongFormWriting/index'),
        meta:{
          name:'AI长篇写作'
        },
      },
      {
        path: '/ai-mind-map',
        name:'AiMindMap',
        component: () => import('@/views/aiMindMap/index'),
        meta:{
          name:'AI思维导图'
        },
      },
      {
        path: '/ai-document-parsing',
        name:'AiDocumentParsing',
        component: () => import('@/views/aiDocumentParsing/index'),
        meta:{
          name:'AI文档解析'
        },
      },
      {
        path: '/ai-arena',
        name:'AiArena',
        component: () => import('@/views/aiArena/index'),
        meta:{
          name:'AI擂台'
        },
      },
      {
        path: '/ai-tool-talk',
        name:'AiToolTalk',
        component: () => import('@/views/aiToolTalk/index'),
        meta:{
          name:'AI对话'
        },
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/401',
    component: () => import('@/views/errorPages/401')
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/errorPages/404')
  }
]
export const router = createRouter({
  history: historyCreatorMap[VITE_ROUTER_HISTORY_MODE](VITE_BASE_URL),
  routes: createBuiltinVueRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

/** Setup Vue Router */
export async function setupRouter(app) {
  app.use(router);
  createProgressGuard(router);
  // router.isReady()是一个Promise对象，它用于等待路由初始化完成，特别是当路由参数被获取后。这个方法确保在路由参数已经准备好并且可以被安全访问之后再进行任何依赖于路由参数的操作，比如发起网络请求。
  await router.isReady();
}
