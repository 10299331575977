import service from '@/utils/service'

export function getModelList() {
  return service({
      url: '/jw-aigc/aigc/pc/get/model/list',
      method: 'get'
  })
}
// 获取聊天列表
export function getChatDetailsList(data) {
  return service({
      url: '/jw-aigc/aigc/pc/get/chat/log/details',
      method: 'post',
      data
  })
}
// 删除日志
export function delChartLog(data) {
  return service({
      url: '/jw-aigc/aigc/pc/del/user/chat/log',
      method: 'post',
      data
  })
}
// 获取AI课程详情
export function getCourseInfo(data) {
  return service({
      url: '/jw-aigc/aigc/pc/get/course/config',
      method: 'get'
  })
}
// 获取项目相关配置信息
export function getProInfo(data) {
  return service({
      url: '/jw-aigc/aigc/pc/get/system/info',
      method: 'get'
  })
}
// 获取用户是否能够进入项目
export function getUserInProjectStatus(data) {
  return service({
      url: '/jw-aigc/aigc/pc/get/student/system/config/status',
      method: 'get'
  })
}
// 获取服务设置详情
export function getServiceConfigInfo(data) {
  return service({
      url: '/jw-aigc/aigc/pc/get/service/config/info',
      method: 'post',
      data
  })
}
