<script setup>
import defaultAvatar from '@/assets/img/avatar.jpg'
import { useSettingStore } from "@/store/modules/setting";
import { useRouter } from "vue-router";
const router = useRouter()
const settingStore = useSettingStore()
const schoolName = computed(()=>settingStore.schoolName)
const systemName = computed(()=>settingStore.systemName)
const logoImg = computed(()=>settingStore.logoImg)
const handleClickPathHome = ()=>{router.push({ path: '/'});}
</script>
<template>
  <div class="head" @click="handleClickPathHome">
    <NImage
      width="68"
      :src="logoImg||defaultAvatar"
      preview-disabled
      class="head_logo"
    />
    <div class="text">
      <div>{{ schoolName }}</div>
      <div>{{ systemName }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@font-face {
  font-family: 'ZiZhiQuXiMaiTiFont';
  src: url('@/assets/fonts/ZiZhiQuXiMaiTi-2.ttf') format('truetype'), /* 对于老版本的iOS */
      url('@/assets/fonts/ZiZhiQuXiMaiTi-2.ttf') format('ttf'); /* 现代浏览器 */
  font-weight: normal;
  font-style: normal;
}
.head{
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  .head_logo{
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 68px;
  }
  .text{
    font-family: ZiZhiQuXiMaiTiFont;
    padding-bottom: 28px;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    background: linear-gradient(-90deg, #0D52FF 0%, #7C48FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
