import Cookies from 'js-cookie'

const TokenKey = 'jwxx-lxlt-user-sso-token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  //return Cookies.set(TokenKey, token)
  if(import.meta.env.MODE !== 'production'){
    return Cookies.set(TokenKey, token)
  }
  return Cookies.set(TokenKey, token, { domain: import.meta.env.VITE_ROOT_DOMAIN_NAME, path: '/' })
}

export function removeToken() {
  //return Cookies.remove(TokenKey)
  if(import.meta.env.MODE !== 'production'){
    return Cookies.remove(TokenKey)
  }
  return Cookies.remove(TokenKey, { domain: import.meta.env.VITE_ROOT_DOMAIN_NAME })
}
