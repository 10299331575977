import service from '@/utils/service'
// 登录
export function login(data) {
  return service({
    url: '/jw-student/login/userLogin',
    method: 'post',
    data,
    headers: {
        isToken: false,
        "Content-Type": 'application/json'
    }
  })
}
// 获取个人信息
export function getInfo() {
  return service({
      url: '/jw-student/user/info/get/info',
      method: 'get'
  })
}
export function getCodeImg(data){
  return service({
      url: '/jw-student/login/getSmsCode',
      method: 'post',
      data,
      headers: {
        isToken: false,
        "Content-Type": 'application/json'
      }
  })
}
