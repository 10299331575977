import { router as globalRouter } from '@/router';
import axios from 'axios'
import { saveAs } from 'file-saver'
import { getToken } from '@/utils/auth'
import { encrypt, decrypt } from '@/utils/aes'
const {VITE_APP_BASE_API, MODE, VITE_TESTLIBRARYBASE_URL, VITE_PAGEKEY, VITE_PAGEVALUE} = import.meta.env
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from '@/utils'
import {useUserStore} from "@/store/modules/user"
const whiteList = ['/jw-aigc/aigc/pc/get/tools','/jw-student/user/info/get/info','/jw-aigc/aigc/pc/get/student/system/config/status','/jw-aigc/aigc/pc/get/model/list', '/jw-aigc/aigc/pc/get/chat/log/list', '/jw-aigc/aigc/pc/get/service/config/info']
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: VITE_APP_BASE_API,
  // 超时
  timeout: 100000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改  1b52a7b7ed4e483cab42ba8b60aa87d0
  }
  if (MODE === "development") {

    config.headers['tenant-url'] = encrypt(JSON.stringify(VITE_TESTLIBRARYBASE_URL+'/'+VITE_PAGEKEY+'/'+VITE_PAGEVALUE))
  } else {
    config.headers['tenant-url'] =  encrypt(JSON.stringify(window.location.href))
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!config.isAes && (config.method === 'post' || config.method === 'put') && typeof config.data === 'object') {
    config.data = {
      parameter: encrypt(JSON.stringify(config.data))
    }
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;

  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401) {
    if(whiteList.includes(res.config.url)){
      useUserStore().resetStore()
      return Promise.resolve({error:'401',data:null})
    }
    if (!isRelogin.show) {
      isRelogin.show = true;
      $dialog.error({
        title: '系统提示',
        content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
        negativeText: '取消',
        positiveText: '重新登录',
        maskClosable: false,
        closeOnEsc: false,
        onPositiveClick() {
          isRelogin.show = false;
          logoutAndCleanup()
        },
        onClose() {
          isRelogin.show = false;
        }
      });
    }
    return {error:'无效的会话，或者会话已过期，请重新登录。',data:null}
  } else if (code === 500) {
    $message.create(`${msg}`,{ type: 'error', duration: 5 * 1000 })
    return {error:msg,data:null}
  } else if (code === 601) {
    $message.create(`${msg}`,{ type: 'warning', duration: 5 * 1000 })
    return {error:msg,data:null}
  } else if (code !== 1&&code !== 200) {
    $notification.error({ content: msg, duration: 5 * 1000, })
    return{error:'error',data:null}
  } else if (code === 16102) {
    globalRouter.push('/401')
  }else {
    //接口返回正确的情况
    try {
      res.data.data = JSON.parse(decrypt(res.data.data))
    } catch (err) { }
    return {data:res.data,error:null}
  }
},
  error => {
    console.log(error)
    console.log('err' + error)
    let { message, status } = error;
    if(status=='401'){
      if(whiteList.includes(error.config.url)){
        useUserStore().resetStore()
        return Promise.resolve({error,data:null})
      }
      if (!isRelogin.show) {
        isRelogin.show = true;
        $dialog.error({
          title: '系统提示',
          content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
          negativeText: '取消',
          positiveText: '重新登录',
          maskClosable: false,
          closeOnEsc: false,
          onPositiveClick() {
            isRelogin.show = false;
            logoutAndCleanup()

          },
          onClose() {
            isRelogin.show = false;
            // logoutAndCleanup();
          }
        });
      }
      return Promise.resolve({error,data:null})
    }
    if(status=='504'){
      $message.warning('请求超时，请稍后重试！')
    }
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    $message.create(`${message}`,{ type: 'error', duration: 5 * 1000 })
    return Promise.resolve({error,data:null})
  }
)
function handleLogout() {
  useUserStore().resetStore()
  if(import.meta.env.MODE==="development"){
    globalRouter.push('/login')
    return
  }
  location.href = import.meta.env.VITE_LOGOUT_PATH + encrypt(window.location.href)
}
function logoutAndCleanup() {
  handleLogout();
  window.removeEventListener('beforeunload', handleLogout);
}
// 通用下载方法
export function download(url, params, filename, config) {
  // downloadLoadingInstance = ElLoading.service({ text: "正在下载数据，请稍候", background: "rgba(0, 0, 0, 0.7)", })
  return service.get(url, {
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      // ElMessage.error(errMsg);
    }
    // downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    // ElMessage.error('下载文件出现错误，请联系管理员！')
    // downloadLoadingInstance.close();
  })
}

export default service
