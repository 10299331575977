<template>
  <Header :height="headerHeight"/>
  <NLayoutContent :style="scrollBarStyle">
    <slot></slot>
    <div class="background-fx">
      <SvgIcon localIcon="shape-01" class="shape-01"/>
      <SvgIcon localIcon="shape-02" class="shape-02"/>
      <SvgIcon localIcon="shape-03" class="shape-03"/>
      <SvgIcon localIcon="shape-04" class="shape-04"/>
      <SvgIcon localIcon="shape-05" class="shape-05"/>
      <SvgIcon localIcon="shape-06" class="shape-06"/>
      <SvgIcon localIcon="shape-07" class="shape-07"/>
      <SvgIcon localIcon="shape-08" class="shape-08"/>
      <SvgIcon localIcon="shape-09" class="shape-09"/>
      <SvgIcon localIcon="shape-10" class="shape-10"/>
      <SvgIcon localIcon="shape-11" class="shape-11"/>
    </div>
  </NLayoutContent>
  <Footer :height="footerHeight"/>
</template>

<script setup>
import bgZhongjian from "@/assets/img/bg_zhongjian.png"
import Header from "./Header.vue"
import Footer from "./Footer.vue"
const headerHeight = 0
const footerHeight = 0
const scrollBarStyle = {
  height: `calc(100% - ${headerHeight + footerHeight + 1}px)`,
  '--n-color':'#0000',
  backgroundImage:`url('${bgZhongjian}')`,
  backgroundSize:'cover',
  backgroundPosition:'top center',
  zIndex:0
}
</script>

<style lang="scss" scoped>
.background-fx{
  position: absolute;
  left: 60px;
  right: 0;
  height: 100%;
  top: 0;
  overflow: hidden;
  opacity: 1;
  z-index: -1;
  .shape-01{
    position: absolute;
    top: 11%;
    right: 42%;
    font-size: 25px;
    animation: animationFramesTwo 13s linear infinite;
  }
  .shape-02 {
    position: absolute;
    top: 27%;
    left: 17%;
    font-size: 36px;
    animation: animationFramesFour 25s linear infinite alternate;
  }
  .shape-03 {
    position: absolute;
    top: 30%;
    left: 50%;
    font-size: 62px;
    animation: animationFramesThree 35s linear infinite alternate;
  }
  .shape-04 {
    position: absolute;
    top: 40%;
    right: 23%;
    font-size: 28px;
    animation: animationFramesFour 20s linear infinite alternate;
  }
  .shape-05 {
    position: absolute;
    bottom: 62%;
    right: 28%;
    font-size: 14px;
    animation: animationFramesOne 15s linear infinite;
  }
  .shape-06 {
    position: absolute;
    bottom: 73%;
    left: 38%;
    font-size: 38px;
    animation: animationFramesFour 20s linear infinite alternate;
  }
  .shape-07 {
    position: absolute;
    left: 14%;
    bottom: 54%;
    font-size: 14px;
    animation: animationFramesOne 17s linear infinite;
  }
  .shape-08 {
    position: absolute;
    left: 14%;
    top: 60%;
    font-size: 21px;
    animation: animationFramesOne 20s linear infinite alternate;
  }
  .shape-09 {
    position: absolute;
    top: 22%;
    left: 41%;
    animation: animationFramesOne 15s linear infinite;
  }
  .shape-10 {
    position: absolute;
    top: 8%;
    right: 6%;
    font-size: 240px;
    animation: animationFramesOne 15s linear infinite;
  }
  .shape-11 {
    position: absolute;
    top: 10%;
    right: 10%;
    font-size: 240px;
    animation: animationFramesOne 12s linear infinite;
  }
  @keyframes animationFramesOne {
    0% {
        transform: translate(0) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0) rotate(0deg);
    }
  }
  @keyframes animationFramesTwo {
    0% {
        transform: translate(0) rotate(0deg) scale(1);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }
    100% {
        transform: translate(0) rotate(0deg) scale(1);
    }
  }
  @keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px);
    }

    100% {
        transform: translate(-346px, 617px);
    }
  }
  @keyframes animationFramesFour {
    0% {
        transform: translate(-300px, 151px) rotate(0deg);
    }

    100% {
        transform: translate(251px, -200px) rotate(180deg);
    }
  }
}
</style>
