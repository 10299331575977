import { createApp } from 'vue'
import App from './App.vue'
import './style/globle.css'
import './plugins/assets';
import './plugins/index';
import { setupRouter } from './router';
import { setupStore } from './store';
const app = createApp(App)
setupStore(app)
setupRouter(app)
app.mount('#app')
// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
    if (window) {
      window.console.log = function () {};
    }
  }
  
