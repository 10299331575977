<script setup>
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore } from '@/store/modules/app';
import Sider from "./components/Sider/index.vue";
import Main from './components/Main.vue';
const appStore = useAppStore()
const collapsed = computed(() => appStore.siderCollapsed);
const { isMobile } = useBasicLayout()
const getContainerStyle = computed(() => {
	return {
    // paddingLeft: (isMobile.value||collapsed.value)?'0':'260px',
    paddingLeft: '260px',
    '--n-color':'#f5fdff',
   }
})
const keep_alive = ['Home']
</script>

<template>
  <div class="h-full overflow-hidden">
    <NLayout class="z-40 transition h-full main_layout" :style="getContainerStyle" has-sider>
      <Sider />
      <NLayoutContent class="h-full">
        <Main>
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <KeepAlive :include="keep_alive">
                <component :is="Component"/>
              </KeepAlive>
            </transition>
          </router-view>
        </Main>
      </NLayoutContent>
    </NLayout>
  </div>
</template>

<style lang="scss" scoped>
.main_layout{
  &>:deep(.n-layout-scroll-container){
    box-shadow: 0px 0px 92px 0px rgba(40,73,105,0.3);
    border-radius: 40px 0px 0px 40px;
  }
}
.transition{
  transition: all .3s;
}
</style>
