import { watch, computed } from "vue";
import { darkTheme, useOsTheme } from "naive-ui";
import { useAppStore } from '@/store/modules/app'
export function useTheme() {
  const appStore = useAppStore()
  const osTheme = useOsTheme();


  const isDark = computed(() => {
    if (appStore.theme === 'auto')
      return osTheme.value === 'dark'
    else
      return appStore.theme === 'dark'
  });
  const theme = computed(() => {
    return isDark.value ? darkTheme : null;
  });
  const themeOverrides = computed(() => {
    if (isDark.value) {
      return {
        common: {},
      }
    }
    return {}
  });
  watch(
    () => isDark.value,
    (dark) => {
      if (dark)
        document.documentElement.classList.add('dark')
      else
        document.documentElement.classList.remove('dark')
    },
    { immediate: true },
  )
  return { theme, themeOverrides }
}
