<script setup>
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/modules/user";
import defaultAvatar from '@/assets/img/avatar.jpg';
import { maskPhoneNumber } from '@/utils/validate'
import { encrypt, decrypt } from '@/utils/aes'
const router = useRouter()
const userStore = useUserStore()
const userInfo = computed(()=>userStore.userInfo)
const userId = computed(()=>userStore.userId)
function handleLogout(){
  $dialog.warning({
    title: '系统提示',
    content: '您确认退出吗？',
    negativeText: '取消',
    positiveText: '确认',
    maskClosable: false,
    closeOnEsc: false,
    onPositiveClick() {
      // 点击确认
      userStore.resetStore()
      // router.push('/login')
      handleLogin()
    },
    onClose() {

    }
  });
}
function handleLogin(){
  if(import.meta.env.MODE==="development"){
    router.push('/login')
    return
  }
  location.href = import.meta.env.VITE_LOGOUT_PATH + encrypt(window.location.href)
}
</script>

<template>
  <footer class="footer h-111px text-center">
    <NButton
    v-if="!userId"
    type="primary"
    class="w-[80%] h-42px mt-31px"
    color="#494dff"
    style="background: linear-gradient(90deg, #0D52FF 0%, #7C48FF 100%);"
    @click="handleLogin">登录 / 注册</NButton>
    <div v-else class="setup_btn h-60px border-rd-8px flex items-center pl-10px pr-10px text-18px">
      <n-avatar
        :size="40"
        :src="userInfo.avatar||defaultAvatar"
        :fallback-src="defaultAvatar"
      />
      <div class="flex-1 pl-6px ellipsis text-left">
        <div class="text-16px ellipsis">{{ userInfo.name }}</div>
        <div class="text-14px text-[#8D9497]">{{maskPhoneNumber(userInfo.phone)}}</div>
      </div>
      <n-button quaternary circle @click="handleLogout">
        <template #icon>
          <SvgIcon localIcon="logout" class="cursor-pointer"/>
        </template>
      </n-button>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer{
  padding: 0 20px;
  .setup_btn{
    background: linear-gradient(270deg, #D5E0E3 0%, #E1E9EB 100%);
  }
}
</style>
