import { getProInfo } from "@/api/common"
export const useSettingStore = defineStore('setting-store',{
  state:()=>({
    configId:undefined,
    icpNum:undefined,
    id:undefined,
    logoImg:undefined,
    publicSecurityNum:undefined,
    schoolName:undefined,
    systemName:undefined,
    isAigc: 0
  }),
  actions: {
    getProjectInfo() {
      return new Promise(async (resolve, reject) => {
        const {data,error} = await getProInfo()
        console.log(data)
        if(!error){
          const {configId,icpNum,id,logoImg,publicSecurityNum,schoolName,systemName, isAigc} = data.data
          console.log(data.data);
          this.configId = configId
          this.icpNum = icpNum
          this.id = id
          this.logoImg = logoImg
          this.publicSecurityNum = publicSecurityNum
          this.schoolName = schoolName
          this.systemName = systemName
          this.isAigc = isAigc
          resolve({data:data.data,error:null})
        }else{
          resolve({data:null, error:error})
        }
      })
    },
  }
})
