<script>
import { computed, defineComponent } from 'vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'SvgIcon',
  inheritAttrs: false,
  props: {
    /** Iconify icon name */
    icon: String,
    /** Local svg icon name */
    localIcon: String
  },
  setup(props, { attrs }) {
    const bindAttrs = computed(() => ({
      class: attrs.class || '',
      style: attrs.style || ''
    }));

    const symbolId = computed(() => {
      const { VITE_ICON_LOCAL_PREFIX: prefix } = import.meta.env;
      const defaultLocalIcon = 'no-icon';
      const icon = props.localIcon || defaultLocalIcon;
      return `#${prefix}-${icon}`;
    });

    /** If localIcon is passed, render localIcon first */
    const renderLocalIcon = computed(() => props.localIcon || !props.icon);

    return { bindAttrs, symbolId, renderLocalIcon };
  },
  components: {
    Icon
  }
});
</script>

<template>
  <template v-if="renderLocalIcon">
    <svg aria-hidden="true" width="1em" height="1em" v-bind="bindAttrs">
      <use :xlink:href="symbolId" fill="currentColor" />
    </svg>
  </template>
  <template v-else>
    <Icon v-if="icon" :icon="icon" v-bind="bindAttrs" />
  </template>
</template>

<style scoped></style>
