import CryptoJS from 'crypto-js';

//随机生成指定数量的16进制key
export function generatekey(num) {
  let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (var i = 0; i < num; i++) {
      let randomPoz = Math.floor(Math.random() * library.length);
      key += library.substring(randomPoz, randomPoz + 1);
  }
  return key;
}

//加密
export function encrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : 'jwxx2023xypj2022'; //判断是否存在ksy，不存在就用定义好的key
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  // return encrypted.toString();// 编码为Base64
  return encrypted.ciphertext.toString().toUpperCase();// 编码为Hex
}
//解密
export function decrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : 'jwxx2023xypj2022';
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);// 将字符串转成hex编码
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);// 将hex编码转成base64编码
  var decrypt = CryptoJS.AES.decrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();// 将解密后字符串转成utf8编码
}
