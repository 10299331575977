<script setup>
import { NEllipsis } from "naive-ui";
import { useRoute, useRouter } from "vue-router";
import { getCourseInfo } from "@/api/common";
import usePermission from "@/hooks/usePermission";
const { chatRoteAndIslogin } = usePermission();
const route = useRoute();
const router = useRouter();
const includesList = ["PracticalTraining", "AiTool"];
const menuOptions = ref([
  {
    label: () => h(NEllipsis, null, { default: () => "AI对话" }),
    key: "AiMessage",
    icon: () => iconImgRender("icon_aiduihua", "50px", "50px"),
  },
  {
    label: () => h(NEllipsis, null, { default: () => "AI实训" }),
    key: "PracticalTraining",
    icon: () => iconImgRender("icon_aishixun", "50px", "49px"),
  },
  {
    label: () => h(NEllipsis, null, { default: () => "AI工具" }),
    key: "AiTool",
    icon: () => iconImgRender("icon_aigongju", "50px", "50px"),
  },
]);
const routeActive = computed(() => route.meta.redirect || route.name);
const course = ref({});
async function courseInfo() {
  const { data, error } = await getCourseInfo();
  if (!error) {
    const { data: info } = data;
    course.value = info;
    if (info.aiCourseStatus === 1) {
      const courseRoute = {
        label: () => h(NEllipsis, null, { default: () => "AI课程" }),
        key: "AiCourse",
        icon: () => iconImgRender("icon_aikecheng", "50px", "50px"),
        children: [],
      };
      if (info.generalEducationCourseStatus === 1) {
        courseRoute.children.push({
          label: "AI通识课",
          key: "generalEducationCourseUrl",
        });
      }
      if (info.practicalTrainingCourseStatus === 1) {
        courseRoute.children.push({
          label: "AI实操课",
          key: "practicalTrainingCourseUrl",
        });
      }
      menuOptions.value.push(courseRoute);
    }
  }
}
const routerPushByKeyWithMetaQuery = (key) => {
  if (route.meta.redirect === key) return;
  if (includesList.includes(key)) {
    chatRoteAndIslogin().then(async () => {
      router.push({ name: key });
    });
    return;
  }

  if (
    ["generalEducationCourseUrl", "practicalTrainingCourseUrl"].includes(key)
  ) {
    window.open(course.value[key], "_blank");
    return;
  }
  router.push({ name: key });
};
function iconImgRender(imgName, width, height, name) {
  return h("img", {
    src: getImg(imgName),
    alt: name,
    style: {
      width,
      height,
    },
  });
}
function getImg(imgName) {
  return new URL(`../../../assets/img/${imgName}.png`, import.meta.url).href;
}
courseInfo();
</script>

<template>
  <NMenu
    :collapsed-width="64"
    :collapsed-icon-size="50"
    :options="menuOptions"
    :value="routeActive"
    @update:value="routerPushByKeyWithMetaQuery"
    class="left_menu"
  />
</template>

<style lang="scss" scoped>
.left_menu.n-menu{
  --n-font-size:18px !important;
}
.left_menu :deep(.n-menu-item) {
  --n-item-height: 69px !important;

  margin-top: 30px;
  .n-menu-item-content::before {
    border-radius: 14px;
  }
  .n-menu-item-content.n-menu-item-content--selected::before {
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
  }
  .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before {
    border: 1px solid #ffffff;
  }
  &:first-of-type {
    .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,
    .n-menu-item-content.n-menu-item-content--selected::before {
      background: linear-gradient(90deg, #ffffff 0%, #f0fbfd 0%, #cdf0ff 100%);
    }
    .n-menu-item-content.n-menu-item-content--selected
      .n-menu-item-content-header {
      color: #326dff;
    }
  }
  &:nth-of-type(2) {
    .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,
    .n-menu-item-content.n-menu-item-content--selected::before {
      background: linear-gradient(90deg, #ffffff 0%, #fbf0ec 0%, #ffd7c9 100%);
    }
    .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,
    .n-menu-item-content.n-menu-item-content--selected
      .n-menu-item-content-header {
      color: #ed5544;
    }
  }
  &:nth-of-type(3) {
    .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,
    .n-menu-item-content.n-menu-item-content--selected::before {
      background: linear-gradient(90deg, #ffffff 0%, #ecfbf8 0%, #c5ffe4 100%);
    }
    .n-menu-item-content.n-menu-item-content--selected
      .n-menu-item-content-header {
      color: #1dd9d6;
    }
  }
  // &:last-of-type {
  //   .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,.n-menu-item-content.n-menu-item-content--selected::before{
  //     background: linear-gradient(90deg, #FFFFFF 0%, #FFFBF2 0%, #FFF0C9 100%);
  //   }
  //   .n-menu-item-content.n-menu-item-content--selected .n-menu-item-content-header {
  //     color: #FE9B06;
  //   }
  // }
}
.left_menu :deep(.n-submenu > .n-menu-item) {
  .n-menu-item-content:not(.n-menu-item-content--disabled):hover::before,
  .n-menu-item-content.n-menu-item-content--selected::before {
    background: linear-gradient(90deg, #ffffff 0%, #fffbf2 0%, #fff0c9 100%);
  }
  .n-menu-item-content.n-menu-item-content--selected
    .n-menu-item-content-header {
    color: #fe9b06;
  }
}
</style>
